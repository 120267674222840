import React, { Component } from 'react';
import { CssBaseline, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import Background from '../assets/bg.png'

const styles = theme => ({
    root: {
        display: "flex",
        backgroundImage: "url(" + Background + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    title: {
        flexGrow: 1,
    },
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
})

// LoginLayout is a layout showing just the login window.
class LoginLayout extends Component {
    state = {}
    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <CssBaseline />
                    <Container maxWidth="lg" className={classes.container}>
                        {this.props.children}
                    </Container>
                </main>
            </div>
        )
    }
}

export default withStyles(styles)(LoginLayout)
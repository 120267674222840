import React, { Component } from 'react'
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';

class PowerStateIcon extends Component {
    state = {

    }
    render() {
        const { status } = this.props

        if (status) {
            return (
                <PowerIcon fontSize="small" color="primary" />
            )
        }

        return (
            <PowerOffIcon fontSize="small" color="secondary" />
        )
    }
}

export default PowerStateIcon;
import React, { Component } from 'react'
import Title from './Title'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import SmallLoading from './SmallLoading'
import { withSnackbar } from 'notistack'
import PowerStateIcon from './PowerStateIcon'
import ChargeStateIcon from './ChargeStateIcon'
import MUIDataTable from "mui-datatables"
import DeviceDetails from './DeviceDetails'

class VCloudList extends Component {
    state = {
        vms: [],
        tableState: null,
        columns: [
            {
                name: "orgName",
                label: "Organisation",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "VDC",
                label: "VDC",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "VApp",
                label: "VApp",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "vCPUs",
                label: "vCPUs",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "memory",
                label: "Memory",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var valueGB = value/1073741824
                        return (
                            <Typography>{valueGB} GB</Typography>
                        )
                    }
                },
            },
            {
                name: "storageFlash",
                label: "Flash",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var valueGB = value/1073741824
                        return (
                            <Typography>{valueGB} GB</Typography>
                        )
                    }
                },
            },
            {
                name: "powerState",
                label: "Power State",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <PowerStateIcon status={value} />
                        )
                    }
                },
            },
            {
                name: "chargeState",
                label: "Charge State",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <ChargeStateIcon status={true} />
                        )
                    }
                },
            },
        ],
        options: {
            responsive: "standard",
            print: false,
            elevation: 1,
            viewColumns: false,
            selectableRows: "none",
            expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: true,
            isRowExpandable: (dataIndex, expandedRows) => {
                // Prevent expand/collapse of any row if there are 1 rows expanded already (but allow those already expanded to be collapsed)
                if (expandedRows.data.length > 0 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
                return true;
              },
            renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                const item = this.state.vms[rowMeta.rowIndex]
                return (
                     <DeviceDetails item={item} allItems={this.state.vms} type="vcloud" client={this.props.client} colSpan={colSpan} />
                );
              },
            //onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded),
            onTableChange: (action, tableState) => {
                this.setState({
                    tableState: tableState,
                })
                if (action === "sort" || action === "columnViewChange") {
                    const active = tableState.activeColumn
                    const dir = tableState.columns[active].sortDirection

                    this.setState(state => {
                        const columns = state.columns.map((v, i) => {
                            if (i === active) {
                                v.options.sortDirection = dir
                            }
                            v.options.display = tableState.columns[i].display
                            return v
                        })
                        return {
                            columns: columns,
                        }
                    })
                }
                if (action === "search") {
                    const text = tableState.searchText

                    this.setState(state => {
                        let options = state.options
                        options.searchText = text
                        return {
                            options: options,
                        }
                    })
                }
            },
        }
    }

    componentDidMount() {
        const getVCloudDevices = async () => {
                if (window.location.port === "3000") {
                    let res = await axios.get('http://localhost:3001/vCloudOrgs')
                    let array = this.traverse(res.data)
                    this.setState({ vms: array })
                } else {
                    let client = this.props.client
                    let res = await axios.get('/v1/resources/vcloud/customer/'+client.id)
                    let array = this.traverse(res.data.vCloudOrgs)
                    this.setState({ vms: array })
                }
            }
        getVCloudDevices()
    }

    traverse(o) {
    //for (var i in o) {
    const orgArray = o.map(org => {

        const orgContainer = {};

        orgContainer.name = org.name
        orgContainer.id = org.id

        const VDCsArray = org.vCloudVDCs.map(vdc => {
            const VDCsContainer = {};

            VDCsContainer.name = vdc.name
            VDCsContainer.id = vdc.id

            const VAppsArray = vdc.VCloudVApps.map(vapp => {
                const VAppsContainer = {};
                
                VAppsContainer.name = vapp.name
                VAppsContainer.id = vapp.id

                const VMArray = vapp.VCloudVMs.map(vm => {
                    let VMContainer = {};
                    
                    VMContainer = vm
                    VMContainer.VApp = VAppsContainer.name
                    VMContainer.VAppId = VAppsContainer.id
                    VMContainer.VDC = VDCsContainer.name
                    VMContainer.VDCId = VDCsContainer.id
                    VMContainer.orgName = orgContainer.name
                    VMContainer.orgId = orgContainer.id

                    return VMContainer
                })
    
                return VMArray

            })

            return VAppsArray

        })

        return VDCsArray
    })

    const flattened = orgArray.flat(3);

    return flattened
}

    render() {
        return this.state.loading ? (
            <React.Fragment>
                <Title>vCloud VMs</Title>
                <SmallLoading></SmallLoading>
            </React.Fragment>
        ) : (
                <React.Fragment>
                    <MUIDataTable
                        title="vCloud VMs"
                        data={this.state.vms}
                        columns={this.state.columns}
                        options={this.state.options}
                    ></MUIDataTable>
                </React.Fragment>
            );
    }
}

export default withSnackbar(VCloudList)

import React, { Component } from 'react'
import Title from './Title'
import axios from 'axios'
import { Button, Typography } from '@material-ui/core'
import SmallLoading from './SmallLoading'
import { withSnackbar } from 'notistack'
import TagList from './TagList'
import FavoriteIcon from './FavoriteIcon'
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from '@material-ui/core'

import AddIcon from "@material-ui/icons/Add";
import { Link } from 'react-router-dom'

import { apiURL } from '../config'

import MUIDataTable from "mui-datatables"

class CustomerList extends Component {

    state = {
        customers: [],
        tableState: null,
        columns: [
            {
                name: "isFavorite",
                label: "Favorite",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const c = tableMeta.rowData[1]
                        return (
                            <div onClick={() => {
                                this.handleFavorite(c)
                            }}>
                                <FavoriteIcon status={value} />
                            </div>
                            
                        )
                    },
                    sortDirection: 'asc'
                },
            },
            {
                name: "id",
                label: "ID",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        //const c = tableMeta.rowData[1]
                        return (
                            <Typography>
                                {value}
                            </Typography>
                        )
                    },
                    display: false
                },
            },
            {
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const c = tableMeta.rowData[1]
                        return (
                            <Button
                                variant="text"
                                onClick={() => {
                                    this.handleSelectClient(c)
                                }}
                                size="small"
                            >
                                {value}
                            </Button>
                        )
                    }
                },
            },
            {
                name: "groupList",
                label: "Services",
                options: {
                    customBodyRender: (value) => {
                        if (value[0] !== undefined) {
                            return <TagList onClick={() => console.log(this.state)} tags={value}></TagList>
                        } else {
                            return null
                        }
                    },
                    filter: true,
                    //filterType: 'dropdown',
                    sort: false,
                },
            },
        ],
        options: {
            responsive: "standard",
            print: false,
            elevation: 1,
            viewColumns: false,
            selectableRows: "none",
            onTableChange: (action, tableState) => {
                this.setState({
                    tableState: tableState,
                })
                if (action === "sort" || action === "columnViewChange") {
                    const active = tableState.activeColumn
                    const dir = tableState.columns[active].sortDirection

                    this.setState(state => {
                        const columns = state.columns.map((v, i) => {
                            if (i === active) {
                                v.options.sortDirection = dir
                            }
                            v.options.display = tableState.columns[i].display
                            return v
                        })
                        return {
                            columns: columns,
                        }
                    })
                }
                if (action === "search") {
                    const text = tableState.searchText

                    this.setState(state => {
                        let options = state.options
                        options.searchText = text
                        return {
                            options: options,
                        }
                    })
                }
            },
            customToolbar: () => {
                return (
                    <Tooltip title={"Add Customer"}>
                        <IconButton button component={Link} to="/addcustomer" >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    }

    handleSelectClient = (cid) => {
        this.state.customers.forEach(c => {
            if (c.id === cid) {
                this.props.setClient(c)
            }
        })
    }

    handleFavorite = (cid) => {
        this.state.customers.forEach(c => {
            if (c.id === cid) {
                if (c.isFavorite) {
                    axios.delete(apiURL + '/customer/' + c.id + '/favorite')
                } else {
                    axios.post(apiURL + '/customer/' + c.id + '/favorite')
                }
            }
        })
    }

    componentDidMount() {
        const getCustomers = async () => {
            let customers
            let res = await axios.get(apiURL + '/customers')
            customers = res.data.customers

            customers.forEach(customer => {
                if (customer.groups !== undefined) {
                    const groups = customer.groups.map(group => {
                        return group.name
                    })
                    customer.groupList = groups
                }
            })

            this.setState({ customers: customers })
        }
        getCustomers()
    }

    componentWillUnmount() {
        //this.ws.close()
    }

    render() {
        return this.state.loading ? (
            <React.Fragment>
                <Title>Customers</Title>
                <SmallLoading></SmallLoading>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <MUIDataTable
                    title="Customers"
                    data={this.state.customers}
                    columns={this.state.columns}
                    options={this.state.options}
                ></MUIDataTable>
            </React.Fragment>
        );
    }
}

export default withSnackbar(CustomerList)
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'

import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import { apiURL } from '../config'
import axios from 'axios'


export default function CreateReport(props) {

    const { client } = props

    console.log(client)

    let emptyReportRequest = {
        "customerID": client.id,
        "from": "",
        "to": "",
        "Measurements": [
          "string"
        ],
        "acceptedDataGapInHours": 0
      }

    const [ReportRequest, setReportRequest] = React.useState(emptyReportRequest)

    const [validDateGap, setValidDateGap] = React.useState(true)

    const handleChangeDateGap = value => {
        if (value >= 1 && value <= 100) {
            setValidDateGap(true)
            setReportRequest(request => ({ ...request, acceptedDataGapInHours: Number(value)})) 

        } else {
            setValidDateGap(false)
        }
        console.log(ReportRequest)
    }

    const sleep = (millis) => {
        return new Promise(res => setTimeout(res, millis))
    }

    const [notification, setNotification] = React.useState()

    const notifySave = (res) => {
        console.log(res)
        if (res.status === 200) {
            setNotification("good")
            sleep(2000).then(r => {
                setNotification("")
            })
        } else {
            setNotification("bad")
            sleep(2000).then(r => {
                setNotification("")
            })
        }
    }

    const handlePostCharge = () => {
        const postCharge = async () => {
            await axios.post(apiURL + '/chargeback/report', ReportRequest)
                .then(res => notifySave(res))
                .catch(res => notifySave(res))
        }
        postCharge()
    }

    const resources = [
        { value: 1, text: "vcloud" },
        { value: 2, text: "meraki" },
    ]

    const [measurements, setMeasurements] = React.useState([])

    const handleChangeMeasurements = value => {
        setReportRequest(request => ({ ...request, Measurements: value})) 
    }

    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date())

    const handleStartDateChange = date => {
        setSelectedStartDate(date)
        setReportRequest(request => ({ ...request, from: date.toISOString()})) 
    }

    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())

    const handleEndDateChange = date => {
        setSelectedEndDate(date)
        setReportRequest(request => ({ ...request, to: date.toISOString()})) 
    }

    return (

        <div>
            <Typography variant="h6">Report</Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={2}>
                    <Typography variant="subtitle1">Customer</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        id="customer"
                        options={[client]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionSelected={(option, value) => option.name === value.name}
                        value={client}
                    //onChange={(event, newValue) => handleChangePriority(newValue)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle1">Resources</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        multiple
                        id="resources"
                        options={resources}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionSelected={(option, value) => option.text === value.text}
                        onChange={(event, newValue) => handleChangeMeasurements(newValue)}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Typography variant="subtitle1">From</Typography>
                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider margin="dense" utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="dense"
                            id="dateStart"
                            label="Start Date"
                            value={selectedStartDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            onChange={handleStartDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2}>
                    <Typography variant="subtitle1">To</Typography>
                </Grid>

                <Grid item xs={4}>
                    <MuiPickersUtilsProvider margin="dense" utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="dense"
                            id="dateEnd"
                            label="End Date"
                            value={selectedEndDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            onChange={handleEndDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle1">Accepted Data Gap</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                                type="number"
                                onChange={event => handleChangeDateGap(event.target.value)}
                                error={!validDateGap}
                                helperText={!validDateGap ? "Must be between 0 and 100" : null}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                                defaultValue={emptyReportRequest.acceptedDataGapInHours}
                            />
                </Grid>

                <Grid item xs={6}>
                </Grid>

                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handlePostCharge}
                    >
                        Generate
                    </Button>
                </Grid>

                <Grid item xs={4}>
                                <Typography>{notification}</Typography>
                            </Grid>



            </Grid>
        </div>
    )
}

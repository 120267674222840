import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { mainListItems } from './listItems'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceIcon from '@material-ui/icons/Face';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
 /*    display: 'grid',
    gridTemplateColumns: 'auto auto auto 1fr auto auto',
    justifyItems: 'center',
    justifyContent: 'space-between', */

  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  textLink: {
    textDecoration: 'none',
    color: 'secondary'
  },
  typography: {
    padding: theme.spacing(2),
  },
});

class Dashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      id: "",
      username: "",
      Drawer: false,
      anchorElUser: null,
      anchorElSupport: null,
    };

    this.props.children.props.keycloak.loadUserInfo().then(userInfo => {
    this.setState({name: userInfo.name, email: userInfo.email, id: userInfo.sub, username: userInfo.preferred_username})
    });

    this.handleLogout = this.handleLogout.bind(this);
  }

 handleLogout() {
    this.props.children.props.keycloak.logout();
  }

  handleUserMenu = event => {
    this.setState({ anchorElUser: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ anchorElUser: null });
  };

  handleSupportMenu = event => {
    this.setState({ anchorElSupport: event.currentTarget });
  };

  handleSupportMenuClose = () => {
    this.setState({ anchorElSupport: null });
  };

  handleDrawerOpen = () => this.setState({Drawer: true});

  handleDrawerClose = () => this.setState({Drawer: false});

  handleResetClient = () => this.props.children.props.setClient("")

  render() {

    const client = this.props.children.props.client
    const { classes } = this.props;
    const { anchorElUser } = this.state;
    const { anchorElSupport } = this.state;
    const { name, username } = this.state;
  
    const openUser = Boolean(anchorElUser);
    const openSupport = Boolean(anchorElSupport);

    return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, this.state.Drawer && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerOpen}
            className={clsx(classes.menuButton, this.state.Drawer && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            My-Mironet
          </Typography>
          <div className={classes.title}>
            { client !== "" ? <Chip label={client.name} onDelete={this.handleResetClient} /> : <div /> }
          </div>



          <IconButton
                  color="inherit"
                  aria-owns={openSupport ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleSupportMenu}
                >
                  <HeadsetMicIcon />
                </IconButton>

          <Popover
                  id="menu-appbar"
                  anchorEl={anchorElSupport}
                  open={openSupport}
                  onClose={this.handleSupportMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <Link href="https://mironet.ch" style={{textDecoration:'none'}} target="_blank" color="inherit">
                    <MenuItem onClick={this.handleSupportMenuClose}>
                  
                  <ListItemIcon>
                      <OpenInNewIcon/>
                  </ListItemIcon>
                  <Typography>Mironet Support</Typography>
                  </MenuItem>
                  </Link>

          </Popover>

          <IconButton
                  color="inherit"
                  aria-owns={openUser ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleUserMenu}
                >
                  <AccountCircleIcon />
                </IconButton>

          <Popover
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  open={openUser}
                  onClose={this.handleUserMenuClose}
                  className={classes.menu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <FaceIcon />
                    </ListItemIcon>
                    <Typography>{name ? name : username}</Typography>
                  </ListItem>
                  <MenuItem onClick={this.handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <Typography>Logout</Typography>
                  </MenuItem>
            </Popover>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !this.state.Drawer && classes.drawerPaperClose),
        }}
        open={this.state.Drawer}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>

      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {this.props.children}
      </main>
    </div>
    );
  }
}

export default withStyles(styles)(Dashboard)

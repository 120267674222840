import React, { Component } from 'react'
import { Route } from 'react-router-dom'

class PublicRoute extends Component {
    render() {
        const { component: Component, layout: Layout, ...rest } = this.props

        const renderRoute = props => {

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )
        }
        return (
            <Route {...rest} render={renderRoute} />
        )
    }
}

export default PublicRoute;
import React, { Component } from 'react'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import Autocomplete from "./Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Title from './Title'

require("./AutocompleteStyle.css");

const styles = theme => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
});


class AddCustomer extends Component {
    state = {
        customers: [],
    }

    handleSelectClient = (cid) => {
        this.state.customers.forEach(c => {
            if (c.id === cid) {
                this.props.setClient(c)
            }
        })
    }

    componentDidMount() {
            const getCustomers = async () => {
                if (window.location.port === "3000") {
                    let res = await axios.get('http://localhost:3001/customers')
                    this.setState({ customers: res.data })
                } else {
                    let res = await axios.get('/v1/customers')
                    this.setState({ customers: res.data.customers })
                }               
            }
            getCustomers()
    }

    componentWillUnmount() {
        //this.ws.close()
    }

    render() {  
        const { classes } = this.props;

        let suggestions = []
        if (this.state.customers.length !== 0) {
            this.state.customers.forEach(c => {
                suggestions.push(c.name)
            })
            console.log(suggestions)
        }
        return (
            <React.Fragment>
                <Title>Add Customer</Title>
                <form className={classes.root} noValidate autoComplete="off">

                <Autocomplete
                    suggestions={suggestions}
                    id="outlined-required"
                    label="Required"
                    defaultValue="Hello World"
                    variant="outlined"
                />
                {/* <TextField
                required
                id="outlined-required"
                label="Required"
                defaultValue="Hello World"
                variant="outlined"
                /> */}
            
            </form>

            </React.Fragment>

            

            );
    }
}

export default withSnackbar(withStyles(styles)(AddCustomer))

import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import CustomerList from './CustomerList'

const styles = theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	}
});

class CustomersContent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		const { classes } = this.props;
		const { getClient } = this.props;
		const { setClient } = this.props;
		return (
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<CustomerList ref="list" setClient={setClient} getClient={getClient}></CustomerList>
					</Grid>
				</Grid>
			</Container>
		)
	}
}

export default withStyles(styles)(CustomersContent)
import React, { Component } from 'react'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

class ChargeStateIcon extends Component {
    state = {

    }
    render() {
        const { status } = this.props

        if (status) {
            return (
                <AttachMoneyIcon fontSize="small" color="primary" />
            )
        }

        return (
            <MoneyOffIcon fontSize="small" color="secondary" />
        )
    }
}

export default ChargeStateIcon;
import React, { Component } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    textfield: {
        marginTop: theme.spacing(2),
    },
    paper: {
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(4),
    }
})

class LoginLoading extends Component {
    state = {
        username: "",
        password: "",
    }

    handleChange = ev => {
        this.setState({
            [ev.target.id]: ev.target.value
        })
    }

    render() {
        const { classes } = this.props
        return (
           	<Grid
               container
               direction="column"
               alignItems="center"
               justify="center"
               //style={{ minHeight: '100vh' }}
            >
                <Paper className={classes.paper}>
                    <Grid item container alignItems='center' direction="column">
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Initializing Keycloak..</Typography>
                        <CircularProgress />
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default withStyles(styles)(LoginLoading)
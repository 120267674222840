import Keycloak from 'keycloak-js'

let apiURL
let keycloakConfig

keycloakConfig = {
   url: process.env.REACT_APP_MYMIRONET_IDP,
   realm: process.env.REACT_APP_MYMIRONET_IDP_REALM,
   clientId: process.env.REACT_APP_MYMIRONET_IDP_CLIENTID,
   onLoad: 'login-required'
}
apiURL = process.env.REACT_APP_MYMIRONET_APIURL

console.log(process.env)

const keycloak = new Keycloak(keycloakConfig);

export { keycloak, apiURL }
import React, { Component } from 'react'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { IconButton } from '@material-ui/core'

class FavoriteIcon extends Component {
    state = {
        fav: this.props.status
    }

    toggle = () => {
        this.setState({
            fav: !this.state.fav
        })
    }

    render() {

        const { status } = this.props

        if (this.state.fav) {
            return (
                <IconButton onClick={this.toggle}>
                    <StarIcon fontSize="small" color="primary" />
                </IconButton>
            )
        }

        return (
            <IconButton onClick={this.toggle}>
                <StarBorderIcon fontSize="small" color="primary" />
            </IconButton>

        )
    }
}

export default FavoriteIcon;
import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'

class SmallLoading extends Component {
    state = {}
    render() {
        return (<CircularProgress size={20}></CircularProgress>)
    }
}

export default SmallLoading
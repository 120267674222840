import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      //justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }));  

export default function TagList(props) {
    const classes = useStyles();

    const { tags } = props

    return (!Array.isArray(tags) || !tags.length) ? (<React.Fragment/>) : (
        <React.Fragment>
            <div className={classes.root}>
            {tags.map(tag => (
                <Chip mr={2} key={tag} label={tag} size="small" />
            ))}
            </div>
        </React.Fragment>
    )
}

import React, { Component } from 'react'
import Title from './Title'
import axios from 'axios'
import { Chip, Tooltip, Typography } from '@material-ui/core'

import SmallLoading from './SmallLoading'
import { withSnackbar } from 'notistack'
import ChargeStateIcon from './ChargeStateIcon'

import MUIDataTable from "mui-datatables"

import { apiURL } from '../config'

const dateShortOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour12: false, }
const dateLongOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false, }

class CostmodelList extends Component {

    state = {
        costmodels: [],
        tableState: null,
        columns: [
            {
                name: "cmName",
                label: "Name",
                options: {
                    customBodyRender: (value) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "fieldName",
                label: "Field Name",
                options: {
                    customBodyRender: (value) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "validity",
                label: "From",
                options: {
                    customBodyRender: (value) => {                        
                        const validityShortFrom = Intl.DateTimeFormat("de-CH", dateShortOptions).format(new Date(value.from))
                        const validityLongFrom = Intl.DateTimeFormat("de-CH", dateLongOptions).format(new Date(value.from))
                        return (
                            <Tooltip title={validityLongFrom}>
                                <Typography>{validityShortFrom}</Typography>
                            </Tooltip>
                        )
                    }
                },
            },
            {
                name: "validity",
                label: "To",
                options: {
                    customBodyRender: (value) => {                        
                        const validityShortTo = Intl.DateTimeFormat("de-CH", dateShortOptions).format(new Date(value.to))
                        const validityLongTo = Intl.DateTimeFormat("de-CH", dateLongOptions).format(new Date(value.to))
                        return (
                            <Tooltip title={validityLongTo}>
                                <Typography>{validityShortTo}</Typography>
                            </Tooltip>
                        )
                    }
                },
            },
            {
                name: "price",
                label: "Price",
                options: {
                    customBodyRender: (value, tableMeta) => {
                        const currency = tableMeta.rowData[5]
                        return (
                            <Typography>{value} {currency}</Typography>
                        )
                    }
                },
            },
            {
                name: "currency",
                options: {
                    display: false
                }
            },
            {
                name: "isCharged",
                label: "Charged",
                options: {
                    customBodyRender: (value) => {
                        return (
                            <ChargeStateIcon status={value} />
                        )
                    }
                },
            },
            {
                name: "discount",
                label: "Discount",
                options: {
                    customBodyRender: (value) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "priority",
                label: "Priority",
                options: {
                    customBodyRender: (value) => {
                        return (
                            <Chip mr={2} label={value/4000} size="small"></Chip>
                        )
                    }
                },
            },
        ],
        options: {
            responsive: "standard",
            print: false,
            elevation: 1,
            viewColumns: false,
            selectableRows: "none",
            expandableRowsHeader: false,
            onTableChange: (action, tableState) => {
                this.setState({
                    tableState: tableState,
                })
                if (action === "sort" || action === "columnViewChange") {
                    const active = tableState.activeColumn
                    const dir = tableState.columns[active].sortDirection

                    this.setState(state => {
                        const columns = state.columns.map((v, i) => {
                            if (i === active) {
                                v.options.sortDirection = dir
                            }
                            v.options.display = tableState.columns[i].display
                            return v
                        })
                        return {
                            columns: columns,
                        }
                    })
                }
                if (action === "search") {
                    const text = tableState.searchText

                    this.setState(state => {
                        let options = state.options
                        options.searchText = text
                        return {
                            options: options,
                        }
                    })
                }
            },
        }
    }

    componentDidMount() {
            const getCostmodels = async () => {
                    if (window.location.port === "3000") {
                    let res = await axios.get(apiURL + '/costModels')
                    let array = this.traverse(res.data)
                    this.setState({ costmodels: array })
                } else {
                    let client = this.props.client
                    let res = await axios.get(apiURL + '/costmodel/'+client.id+'?measurements=vcloud&measurements=meraki')
                    let array = this.traverse(res.data.costModels)
                    this.setState({ costmodels: array })
                }
            }
            getCostmodels()
    }

    traverse(c) {
        const cmArray = c.map(cm => {

            const cmContainer = {};

            cmContainer.name = cm.name
            cmContainer.customerId = cm.customerId
            cmContainer.validity = cm.validity
            cmContainer.priority = cm.priority

            const chargeableArray = cm.chargeableList.map(c => {
                const chargeableContainer = {};

                chargeableContainer.cmName = cmContainer.name
                chargeableContainer.customerId = cmContainer.customerId

                chargeableContainer.validity = c.validity
                chargeableContainer.priority = c.priority
                chargeableContainer.fieldName = c.fieldName
                chargeableContainer.discount = c.discount
                chargeableContainer.price = c.price
                chargeableContainer.maxAmount = c.maxAmount
                chargeableContainer.currency = c.currency
                chargeableContainer.isCharged = c.isCharged

                return chargeableContainer

            })

            return chargeableArray

        })

        const flattened = cmArray.flat(1);

        return flattened
    }

    render() {
        return this.state.loading ? (
            <React.Fragment>
                <Title>Costmodels</Title>
                <SmallLoading></SmallLoading>
            </React.Fragment>
        ) : (
                <React.Fragment>
                    <MUIDataTable
                        title="Costmodels"
                        data={this.state.costmodels}
                        columns={this.state.columns}
                        options={this.state.options}
                    ></MUIDataTable>
                </React.Fragment>
            );
    }
}

export default withSnackbar(CostmodelList)
import { createSlice } from '@reduxjs/toolkit'

export const costModelSlice = createSlice({
  name: 'costModel',
  initialState: {
    customerId: null,
    name: "",
    measurement: "",
    discount: null,
    isCharged: false,
    hierarchy: ["", "", ""],
    validity: {
        from: "",
        to: ""
    },
    priority: null
  },
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload
    },
  }
})

export const {
    setCustomerId
} = costModelSlice.actions

export default costModelSlice.reducer
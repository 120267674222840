import React from 'react'
import { Route } from 'react-router-dom'

export function PrivateRoute({ component: Component, layout: Layout, userName, setClient, getClient, keycloak, client, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => ( 
                    <Layout>
                        <Component {...props} userName={userName} setClient={setClient} getClient={getClient} keycloak={keycloak} client={client} />
                    </Layout>
            )}
        />
    )
}
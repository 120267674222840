import React, { Component } from 'react'
import './App.css';
import Dashboard from './components/Dashboard'
import { Redirect, Switch, withRouter } from 'react-router-dom'
import LoginLayout from './layouts/LoginLayout'
import { PrivateRoute } from './routes/PrivateRoute'
import DashboardContent from './components/DashboardContent'
import CustomersContent from './components/CustomersContent'
import ReportsContent from './components/ReportsContent'
import AddCustomerContent from './components/AddCustomerContent'
import LoginLoading from './components/LoginLoading'

import ResourcesContent from './components/ResourcesContent'

import PublicRoute from './routes/PublicRoute'
import Axios from 'axios'
import { withSnackbar } from 'notistack'

import { keycloak } from './config'
import CostmodelContent from './components/CostmodelContent';

Axios.interceptors.request.use(request => {
	request.headers["Authorization"] = "Bearer " + window.accessToken
	return request;
})


class App extends Component {
	state = {
		loggedIn: false,
		userName: "",
		client: "",
		keycloak: null,
		authenticated: false
	}

	componentDidMount() {
		keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
			this.setState({ keycloak: keycloak, authenticated: authenticated })
			if (authenticated) {
				window.accessToken = keycloak.token
			}
		})
	}

	setClient = (c) => {
		this.setState({
			client: c,
		})
	}
	getClient = () => {
		return this.state.client
	}

	render() {
		if (this.state.keycloak) {
			if (this.state.authenticated) return (
				<Switch>
					<PrivateRoute exact path="/" layout={Dashboard} component={DashboardContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} userName={this.getUserName} client={this.state.client}>
						<Redirect to="/customers" />
					</PrivateRoute>
					<PrivateRoute exact path="/customers" layout={Dashboard} component={CustomersContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} userName={this.getUserName} setClient={this.setClient} client={this.state.client} />
					<PrivateRoute exact path="/addcustomer" layout={Dashboard} component={AddCustomerContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} userName={this.getUserName} setClient={this.setClient} client={this.state.client} />
					<PrivateRoute exact path="/resources" layout={Dashboard} component={ResourcesContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} setClient={this.setClient} client={this.state.client} />
					<PrivateRoute exact path="/costmodels" layout={Dashboard} component={CostmodelContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} setClient={this.setClient} client={this.state.client} />
					<PrivateRoute exact path="/reports" layout={Dashboard} component={ReportsContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} setClient={this.setClient} client={this.state.client} />
					{/* <PrivateRoute exact path="/bills-attention" layout={Dashboard} component={AlertsContent} keycloak={this.state.keycloak} isAuth={this.checkAuth} setClient={this.setClient} client={this.state.client} /> */}
				</Switch>
			); else return (<div>Unable to authenticate!</div>)
		}
		return (
			<PublicRoute layout={LoginLayout} component={LoginLoading} />
		);
	}
}

export default withSnackbar(withRouter(App))

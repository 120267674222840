import React, { Component } from 'react'
import Title from './Title'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import SmallLoading from './SmallLoading'
import { withSnackbar } from 'notistack'
import TagList from './TagList'

import MUIDataTable from "mui-datatables"
import DeviceDetails from './DeviceDetails'

import { apiURL } from '../config'


class MerakiList extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        devices: [],
        tableState: null,
        columns: [
            {
                name: "orgName",
                label: "Organisation",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "networkName",
                label: "Network",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "Model",
                label: "Model",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "serial",
                label: "Serial",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Typography>{value}</Typography>
                        )
                    }
                },
            },
            {
                name: "tags",
                label: "Tags",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value[0] !== undefined) {
                            return (
                            <TagList tags={value}></TagList>
                            )
                        } else {
                            return null
                        }
                    },
                    filter: true,
                    sort: false,
                },
            },
        ],
        options: {
            responsive: "standard",
            print: false,
            elevation: 1,
            viewColumns: false,
            selectableRows: "none",
            expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: true,
            isRowExpandable: (dataIndex, expandedRows) => {
                // Prevent expand/collapse of any row if there are 1 rows expanded already (but allow those already expanded to be collapsed)
                if (expandedRows.data.length > 1 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) return false;
                return true;
              },
            renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                const item = this.state.devices[rowMeta.rowIndex]
                return (
                     <DeviceDetails item={item} allItems={this.state.devices} type="meraki" client={this.props.client} colSpan={colSpan} />
                );
              },

            onTableChange: (action, tableState) => {
                this.setState({
                    tableState: tableState,
                })
                if (action === "sort" || action === "columnViewChange") {
                    const active = tableState.activeColumn
                    const dir = tableState.columns[active].sortDirection

                    this.setState(state => {
                        const columns = state.columns.map((v, i) => {
                            if (i === active) {
                                v.options.sortDirection = dir
                            }
                            v.options.display = tableState.columns[i].display
                            return v
                        })
                        return {
                            columns: columns,
                        }
                    })
                }
                if (action === "search") {
                    const text = tableState.searchText

                    this.setState(state => {
                        let options = state.options
                        options.searchText = text
                        return {
                            options: options,
                        }
                    })
                }
            },
        }
    }

    componentDidMount() {
            const getMerakiDevices = async () => {
                    if (window.location.port === "3000") {
                    let res = await axios.get(apiURL + '/merakiOrgs')
                    let array = this.traverse(res.data)
                    console.log(res)
                    this.setState({ devices: array })
                } else {
                    let client = this.props.client
                    let res = await axios.get(apiURL + '/resources/meraki/customer/'+client.id)
                    let array = this.traverse(res.data.merakiOrgs)
                    this.setState({ devices: array })
                }
            }
            getMerakiDevices()
    }

    traverse(o) {
        const orgArray = o.map(org => {

            const orgContainer = {};

            orgContainer.name = org.name
            orgContainer.id = org.id

            const networkArray = org.networks.map(net => {
                const networkContainer = {};

                networkContainer.name = net.name
                networkContainer.id = net.id

                const deviceArray = net.merakiDevices.map(device => {
                    let deviceContainer = {};

                    deviceContainer = device
                    deviceContainer.networkName = networkContainer.name
                    deviceContainer.orgName = orgContainer.name

                    const tagArray = device.tags.map((value, index) => {
                        /* const tagContainer = {};

                        tagContainer.id = index
                        tagContainer.name = value */

                        return value
                    })

                    deviceContainer.tags = tagArray

                    return deviceContainer
                })

                return deviceArray
            })

            return networkArray
        })

        const flattened = orgArray.flat(2);

        console.log(flattened)

        return flattened
    }

    render() {
        return this.state.loading ? (
            <React.Fragment>
                <Title>VCloud VMs</Title>
                <SmallLoading></SmallLoading>
            </React.Fragment>
        ) : (
                <React.Fragment>
                    <MUIDataTable
                        title="Meraki Devices"
                        data={this.state.devices}
                        columns={this.state.columns}
                        options={this.state.options}
                    ></MUIDataTable>
                </React.Fragment>
            );
    }
}

export default withSnackbar(MerakiList)

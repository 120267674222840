import React from 'react';
import { render } from 'react-dom';
import './index.css';
//import 'typeface-roboto'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { SnackbarProvider } from 'notistack';
import store from './store'
import { Provider } from 'react-redux'

const theme = createMuiTheme({
    palette: {
      primary: {
        // MiroNet Color
        main: '#006a8b'
      },
      secondary: {
        // MiroCare Color
        main: '#a6004a',
      },
    },
  });

  render (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
    <App />
    </Provider>
    </SnackbarProvider>
    </BrowserRouter>
    </ThemeProvider>,
    document.querySelector('#root')
)

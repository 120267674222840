import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import AddCustomer from './AddCustomer'

const styles = theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	input: {
		border: '1px solid #999',
		padding: '0.5rem',
		width: '300px',
	},
	noSuggestions: {
		color: '#999',
		padding: '0.5rem',
	}
});



class AddCustomerContent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}


	render() {
		console.log("hi ")

		const { classes } = this.props;
		return (
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
						<AddCustomer></AddCustomer>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		)
	}
}

export default withStyles(styles)(AddCustomerContent)
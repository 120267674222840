import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import CreateReport from './CreateReport'
import ReportList from './ReportList'

const styles = theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	}
});

class ReportsContent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>

					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<CreateReport client={this.props.client} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<ReportList ref="list" setClient={this.props.setClient} getClient={this.props.getClient} />
					</Grid>
				</Grid>
			</Container>
		)
	}
}

export default withStyles(styles)(ReportsContent)
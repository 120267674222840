import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PowerStateIcon from './PowerStateIcon'
import ChargeStateIcon from './ChargeStateIcon'
import { TextField, Typography } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment'

import { apiURL } from '../config'
import axios from 'axios'

export default function DeviceDetails(props) {

    const { item, type, client, colSpan } = props

    const [openCostModel, setOpenCostModel] = React.useState(0)

    const handleOpenCostModel = (e) => {
        const nr = Number(e.currentTarget.value)
        if (openCostModel === 0) {
            setOpenCostModel(nr)

            var orgId = item.orgId.split(/[\s:]+/).pop()
            var vdcId = item.VDCId.split(/[\s:]+/).pop()

            if (nr === 1) {
                setCostModelRequest(request => ({ ...request, hierarchy: orgId + ':' + vdcId })) 
            }
            var vappId = item.VAppId.split(/[\s:]+/).pop()

            if (nr === 2) {
                setCostModelRequest(request => ({ ...request, hierarchy: orgId + ':' + vdcId + ':' + vappId})) 
            }

            var vmId = item.id.split(/[\s:]+/).pop()
            if (nr === 3) {
                setCostModelRequest(request => ({ ...request, hierarchy: orgId + ':' + vdcId + ':' + vappId + ':' + vmId})) 
            }
        }
        else {
            setOpenCostModel(0)
        }
    }

    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date())

    const handleStartDateChange = date => {
        setSelectedStartDate(date)
        setCostModelRequest(request => ({ ...request, validity: {from: date.toISOString(), to: selectedEndDate.toISOString()}})) 
    }

    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())

    const handleEndDateChange = date => {
        setSelectedEndDate(date)
        setCostModelRequest(request => ({ ...request, validity: {from: selectedStartDate.toISOString(), to: date.toISOString()}})) 

    }

    const [enableDate, setEnableDate] = React.useState({ start: false, end: false })

    const handleEnableDate = (event) => {
        setEnableDate({ ...enableDate, [event.target.name]: event.target.checked })
    }

    const [charge, setCharge] = React.useState(false)

    const handleCharge = (event) => {
        setCharge(event.target.checked)
        setCostModelRequest(request => ({ ...request, isCharged: event.target.checked})) 
    }

    const [validDiscount, setValidDiscount] = React.useState(true)

    const handleChangeDiscount = value => {
        if (value >= 1 && value < 100) {
            setValidDiscount(true)
            setCostModelRequest(request => ({ ...request, discount: value})) 

        } else {
            setValidDiscount(false)
        }
    }

    const handleChangePriority = value => {
        setCostModelRequest(request => ({ ...request, priority: value.value*4000})) 
    }

    let emptyCostModelRequest = {
        "customerId": client.id,
        "name": "",
        "measurement": type,
        "discount": 0,
        "isCharged": false,
        "hierarchy": "",
        "validity": {
            "from": "",
            "to": ""
        },
        "priority": null
    }

    const [costModelRequest, setCostModelRequest] = React.useState(emptyCostModelRequest)

    const handleSetCostModelTitle = event => {
        setCostModelRequest(request => ({ ...request, name: event.target.value }))
        console.log(costModelRequest)
    }

    let levels = []

    if (type === "vcloud") {
        levels = [{ id: 1, value: "VDC" }, { id: 2, value: "VApp" }, { id: 3, value: "VM" }]
    }

    if (type === "meraki") {
        levels = [{ id: 1, value: "Network" }, { id: 2, value: "Device" }]
    }

    const priorities = [
        { value: 1, text: "1 - very high" },
        { value: 2, text: "2 - high" },
        { value: 3, text: "3 - medium" },
        { value: 4, text: "4 - low" },
        { value: 5, text: "5 - very low" }
    ]

    const handlePostCostModel = () => {
        const postCostModel = async () => {
            const res = await axios.post(apiURL + '/costmodel/resource', costModelRequest)
            .then(res => notifySave(res))
            .catch(res => notifySave(res))
        }
        postCostModel()
    }

    const sleep = (millis) => {
        return new Promise(res => setTimeout(res, millis))
    }

    const [notification, setNotification] = React.useState()
    
    const notifySave = (res) => {
        console.log(res)
        if (res.status === 200) {
            console.log("goodo")
            setNotification("good")
            sleep(2000).then(r => {
                setNotification("")
            })                
        } else {
            setNotification("good")
            console.log("bado")
            sleep(2000).then(r => {
                setNotification("")
            })
        }
    }

    return (
        <TableRow style={{ backgroundColor: "#fafafa" }}>
            <TableCell colSpan={colSpan}>
                {type === "vcloud" ?
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">ID</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{item.id}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Power State</Typography>
                        </Grid>

                        <Grid item container xs={2} alignItems="center" justify="space-between">
                            <PowerStateIcon status={item.powerState} />
                            <Typography>01.05.2021</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Serial</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{item.serial}</Typography>
                        </Grid>
                    </Grid>
                }
                <Divider style={{ margin: 12 }} />

                {type === "vcloud" ?
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Create Costmodel</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" size="small" value={1} onClick={(e) => handleOpenCostModel(e)} startIcon={<KeyboardArrowDownIcon />}>{levels[0].value}</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" size="small" value={2} onClick={(e) => handleOpenCostModel(e)} startIcon={<KeyboardArrowDownIcon />}>{levels[1].value}</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" size="small" value={3} onClick={(e) => handleOpenCostModel(e)} startIcon={<KeyboardArrowDownIcon />}>{levels[2].value}</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Charge State</Typography>
                        </Grid>
                        <Grid item container xs={2} alignItems="center" justify="space-between">
                            <ChargeStateIcon status={true} />
                            <Typography>01.05.2021</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Create Costmodel</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" size="small" onClick={handleOpenCostModel} >{item.networkName}</Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" size="small">{item.name}</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">Charge State</Typography>
                        </Grid>
                        <Grid item container xs={2} alignItems="center" justify="space-between">
                            <ChargeStateIcon status={true} />
                            <Typography>01.05.2021</Typography>
                        </Grid>
                    </Grid>
                }

                {openCostModel !== 0 ? <div>
                    <Grid container spacing={3} alignItems="center" >
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Title"
                                onChange={handleSetCostModelTitle}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Level"
                                defaultValue={levels[openCostModel - 1].value}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Name"
                                defaultValue={openCostModel === 1 ? item.VDC : openCostModel === 2 ? item.VApp : item.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Autocomplete
                                id="priority"
                                options={priorities}
                                getOptionLabel={(option) => option.text}
                                renderInput={(params) => <TextField {...params} label="Priority" />}
                                getOptionSelected={(option, value) => option.text === value.text}
                                onChange={(event, newValue) => handleChangePriority(newValue)}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={charge}
                                        onChange={handleCharge}
                                        name="charge"
                                        color="primary"
                                    />
                                }
                                label="charged"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                disabled={!charge}
                                fullWidth
                                label="Discount"
                                type="number"
                                onChange={event => handleChangeDiscount(event.target.value)}
                                error={!validDiscount}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                            />

                        </Grid>

                        <Grid item xs={3}>

                        </Grid>

                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider margin="dense" utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="dense"
                                    id="dateStart"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    disabled={enableDate.start ? true : null}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableDate.start}
                                        onChange={handleEnableDate}
                                        name="start"
                                        color="primary"
                                    />
                                }
                                label="none"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider margin="dense" utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="dense"
                                    id="dateEnd"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                    disabled={enableDate.end ? true : null}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableDate.end}
                                        onChange={handleEnableDate}
                                        name="end"
                                        color="primary"
                                    />
                                }
                                label="none"
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                onClick={handlePostCostModel}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>{notification}</Typography>
                        </Grid>

                    </Grid>
                </div> : null}
            </TableCell>
        </TableRow>
    )
}
